import React from 'react';
import { withPrefix, graphql } from 'gatsby';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';
import InheritanceTaxCalculator from '../../layouts/inheritance-tax-calculator';

// Components
import Section from '../../components/section';
import PageHeader from '../../components/page-header';
import Content, { HTMLContent } from "../../components/content-renderer";

const InheritanceTax = (props) => {
    const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />

            <PageHeader
                heading={pageInfo.frontmatter.title}
                imageSource={`${withPrefix("/")}img/${headerImage}`}
                modalContent={(
                    <PageContent content={pageInfo.html} />
                )}
                description={pageInfo.frontmatter.description}
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Personal finance', url: '/personal-finance'},
                ]}
            />

            <Section
				label='Step tracker calculator'
				variants={['padded', 'secondary', 'secondary-first']}
			>
                <InheritanceTaxCalculator definitions={definitions} />
            </Section>
        </Default>
    );
};

export default InheritanceTax;

export const InheritanceTaxQuery = graphql`
    query InheritanceTaxQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id    
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/main-property|/src/definitions/property-value|src/definitions/outstanding-mortgage|src/definitions/cash|/src/definitions/investments|/src/definitions/life-insurance|/src/definitions/assets|/src/definitions/liabilities|/src/definitions/direct-descendant|/src/definitions/charity-donation|/src/definitions/total-estate-value|/src/definitions/total-liability-value|/src/definitions/net-estate-value|/src/definitions/tax-free-threshold|/src/definitions/taxable-estate/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;