/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import Table from '../../../components/table';
import InfoModal from '../../../components/info-modal';
import ShareResults from '../../../components/share-results';
import Content, { HTMLContent } from "../../../components/content-renderer";

// Helpers
import withShareResults from '../../../helpers/with-share-results';

const Results = ({
    inheritanceTaxPaid,
    totalEstate,
    liabilityValue,
    charityDonation,
    netEstate,
    taxFreeThreshold,
    taxableEstate,
    taxRate,
    definitions,
}) => {
    const PageContent = HTMLContent || Content;

    const { getShareLink } = withShareResults();

    // React Redux hooks
    const figures = useSelector(state => state.yearlyFigures);
    const query = useSelector(state => state.inheritanceTax);

    return (
        <>
            <Table>
                <Table.Header>
                    <th></th>
                    <th>Amount</th>
                </Table.Header>
                <tbody>
                    <tr>
                        <td>
                            <InfoModal
                                triggerText='Total estate value'
                                heading={definitions[13].frontmatter.title}
                            >
                                <PageContent content={definitions[13].html} />
                            </InfoModal>
                        </td>
                        <td>£{totalEstate}</td>
                    </tr>
                    <tr>
                        <td>
                            <InfoModal
                                triggerText='Total liability value'
                                heading={definitions[14].frontmatter.title}
                            >
                                <PageContent content={definitions[14].html} />
                            </InfoModal>
                        </td>
                        <td>£{liabilityValue}</td>
                    </tr>
                    {query.charityDonation !== 0 && (
                        <tr>
                            <td>Charity donation{query.charityDonationAsPercentage ? ` (${query.charityDonation}%)` : ''}</td>
                            <td>£{charityDonation}</td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <InfoModal
                                triggerText='Net estate value'
                                heading={definitions[8].frontmatter.title}
                            >
                                <PageContent content={definitions[8].html} />
                            </InfoModal>
                        </td>
                        <td>£{netEstate}</td>
                    </tr>
                    <tr>
                        <td>
                            <InfoModal
                                triggerText='Tax-free threshold'
                                heading={definitions[11].frontmatter.title}
                            >
                                <PageContent content={definitions[11].html} />
                            </InfoModal>
                        </td>
                        <td>£{taxFreeThreshold}</td>
                    </tr>
                    <tr>
                        <td>
                            <InfoModal
                                triggerText='Taxable estate'
                                heading={definitions[12].frontmatter.title}
                            >
                                <PageContent content={definitions[12].html} />
                            </InfoModal>
                        </td>
                        <td>£{taxableEstate}</td>
                    </tr>
                    <tr>
                        <td>Tax rate</td>
                        <td>{taxRate}%</td>
                    </tr>
                </tbody>

                <Table.Footer>
                    <tr>
                        <td>Inheritance tax:</td>
                        <td>£{inheritanceTaxPaid}</td>
                    </tr>
                </Table.Footer>
            </Table>

            <div className='summary-card__controls'>
                <ShareResults url={getShareLink(query, figures)} />
            </div>
        </>
    );
};

Results.propTypes = {
    inheritanceTaxPaid: PropTypes.string.isRequired,
    totalEstate: PropTypes.string.isRequired,
    liabilityValue: PropTypes.string.isRequired,
    charityDonation: PropTypes.string.isRequired,
    netEstate: PropTypes.string.isRequired,
    taxFreeThreshold: PropTypes.string.isRequired,
    taxableEstate: PropTypes.string.isRequired,
    taxRate: PropTypes.number.isRequired,
    definitions: PropTypes.array.isRequired,
};

export default Results;