import React from 'react';
import PropTypes from 'prop-types';

// Style imports
import '../../sass/components/input-table.scss';

const InputTableRow = ({
    variant,
    heading,
    children,
    toggled,
    toggleContent,
    handleRemove,
}) => {
    const baseClass = 'input-table__row';
    const variantClass = variant ? `input-table__row--${variant}` : '';
    const classes = [baseClass, variantClass].filter(Boolean).join(' ');

    return (
        <article className={classes}>
            {heading && (
                <h3 className='input-table__row-heading'>{heading}</h3>
            )}
            
            <div className='input-table__row-inner'>
                <div className='input-table__row-options'>
                    {children}
                </div>

                {toggled && (
                    <div className='input-table__row-options input-table__row-options--sub'>
                        {toggleContent}
                    </div>
                )}
            </div>
            
            <button className='input-table__row-button' onClick={handleRemove}>
                <span className='accessible'>Remove row</span>
            </button>
        </article>
    );
};

InputTableRow.propTypes = {
    heading: PropTypes.string,
    children: PropTypes.node.isRequired,
    toggled: PropTypes.bool,
    toggleContent: PropTypes.node,
    handleRemove: PropTypes.func,
};

export default InputTableRow;