import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import InputTableRow from '../input-table/row';
import InputField from '../input-field';

const LiabilityRow = ({
    index,
    liabilityNameValue,
    liabilityValueValue,
    onChange,
    handleRemoveRow,
}) => {
    return (
        <InputTableRow
            heading={`${index+1}.`}
            handleRemove={handleRemoveRow}
        >
            <InputField
                id={`liability-name-${index}`}
                required
                labelText='Liability name'
                type='text'
                dataIndex={index}
                dataName='liabilityName'
                value={liabilityNameValue}
                onChange={onChange}
            />

            <InputField
                id={`liability-value-${index}`}
                required
                labelText='Liability value'
                symbol='currency'
                placeholder="0"
                min={0}
                dataIndex={index}
                dataName='liabilityValue'
                value={liabilityValueValue}
                onChange={onChange}
            />
        </InputTableRow>
    );
};

LiabilityRow.propTypes = {
    index: PropTypes.number,
    liabilityNameValue: PropTypes.any,
    liabilityValueValue: PropTypes.any,
    onChange: PropTypes.func,
    handleRemoveRow: PropTypes.func,
};

export default LiabilityRow;