import React from 'react';
import PropTypes from 'prop-types';

// Component imports
import Button from '../button';
import InfoModal from '../info-modal';

// Style imports
import '../../sass/components/input-table.scss';

const InputTable = ({
    heading,
    children,
    buttonText,
    onClick,
    modalHeading,
    modalContent,
}) => {
    return (
        <section className='input-table'>
          <div className='input-table__heading-wrapper'>
            <h3 className='input-table__heading'>{heading}</h3>

            {modalContent && (
              <InfoModal heading={modalHeading}>
                {modalContent}
              </InfoModal>
            )}
          </div>

          {children}

          {onClick && (
            <Button
              icon='add'
              onClick={onClick}
            >
              {buttonText}
            </Button>
          )}
        </section>
    );
};

InputTable.defaultProps = {
    buttonText: 'Add another'
};

InputTable.propTypes = {
   children: PropTypes.node.isRequired,
   heading: PropTypes.string.isRequired,
   buttonText: PropTypes.string,
   onClick: PropTypes.func,
   modalHeading: PropTypes.string,
   modalContent: PropTypes.node,
};

export default InputTable;