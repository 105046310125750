/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import StepTracker from '../../components/step-tracker';

// Partials
import Property from './steps/property';
import Assets from './steps/assets';
import Liabilities from './steps/liabilities';
import OtherInformation from './steps/other-information';
import Results from './steps/results';

// Helpers
import withShareResults from '../../helpers/with-share-results';
import { checkNull } from '../../helpers/check-null';

const InheritanceTax = ({
	definitions,
}) => {
	const { selectedYearParam } = withShareResults();

    // React Redux hooks
	const dispatch = useDispatch();
	const figures = useSelector(state => state.yearlyFigures);
	const query = useSelector(state => state.inheritanceTax);

	useEffect(() => {
        return () => {
			dispatch({ type: 'RESET_YEAR' });
			dispatch({type: 'RESET_INHERITANCE_TAX'});
		};
    }, []);

	//  Applies url params on load
	useEffect(() => {
        if (selectedYearParam) {
            dispatch({ type: 'SET_YEAR', payload: selectedYearParam });
        }
 
        dispatch({ type: 'APPLY_INHERITANCE_TAX_URL_PARAMS' });
     }, [selectedYearParam]);

	// Format numbers to 2 d.p. or 0.00 when 0
    const formatNumber = (val) => {
		let formatted = 0;
		
		if (val < 0) {
			formatted = '0.00';
		} else {
			formatted = val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
		}
		
		return formatted;
	};

	// Additional properties owned value
	let totalAdditionalPropertyValue = 0;
	let totalAdditionalPropertyMortgage = 0;
	for (let i = 0; i < query.propertyState.length; i++) {
		totalAdditionalPropertyValue += checkNull(query.propertyState[i].propertyValue);

		totalAdditionalPropertyMortgage += checkNull(query.propertyState[i].propertyMortgage);
	}
	const calcTotalOwnedAdditionalProperty = (totalAdditionalPropertyValue - totalAdditionalPropertyMortgage) < 0 ? 0 : totalAdditionalPropertyValue - totalAdditionalPropertyMortgage;

	// Main property value minus mortgage
	const calcOwnedMainPropertyValue = checkNull(query.mainPropertyMortgage) > checkNull(query.mainPropertyValue) ? 0 :
		checkNull(query.mainPropertyValue) < 0 ? 0 :
		checkNull(query.mainPropertyValue) - checkNull(query.mainPropertyMortgage);

	// Total owned value of properties
	const calcTotalOwnedPropertyValue = calcOwnedMainPropertyValue + calcTotalOwnedAdditionalProperty;

	// Total value of owned assets
	let totalAdditionalAssetValue = 0;
	for (let i = 0; i < query.assetState.length; i++) {
		totalAdditionalAssetValue += checkNull(query.assetState[i].assetValue);
	}

	// Total value of liabilities
	let totalLiabilityValue = 0;
	for (let i = 0; i < query.liabilityState.length; i++) {
		totalLiabilityValue += checkNull(query.liabilityState[i].liabilityValue);
	}

	// Total estate value calculation
	const calcTotalEstate = calcTotalOwnedPropertyValue + checkNull(query.cash) + checkNull(query.investments) + checkNull(query.lifeInsurance) + totalAdditionalAssetValue;

	// Charity donation as a fixed amount or as a percentage of estate
	const calcCharityDonation = query.charityDonationAsPercentage ? (calcTotalEstate * (checkNull(query.charityDonation) / 100)) : checkNull(query.charityDonation);
	
	// Net estate value calculation
	const calcNetEstate = calcTotalEstate - totalLiabilityValue - calcCharityDonation;

	// Inheritance Tax Rate calculation
	const calcTaxRate = calcCharityDonation === 0 ? figures.inheritanceTaxRate :
		calcCharityDonation < (calcTotalEstate * .1) ? figures.inheritanceTaxRate :
		figures.inheritanceCharityTaxRate;

	// Tax-free threshold calculation
	const calcTaxFreeReduction = (calcNetEstate >= figures.inheritanceTaperThreshold) ? ((calcNetEstate - figures.inheritanceTaperThreshold) / 2) : 0;

	const calcDirectDescendantsAllowance = (calcNetEstate >= figures.inheritanceTaperThreshold) ? figures.inheritanceDescendantsAllowance - calcTaxFreeReduction : figures.inheritanceDescendantsAllowance;

	const correctedDirectDescendantsAllowance = calcDirectDescendantsAllowance <= 0 ? 0 : calcDirectDescendantsAllowance;

	const calcTaxFreeThreshold = query.directDescendant ? figures.inheritanceThreshold + correctedDirectDescendantsAllowance : figures.inheritanceThreshold;

	// Inheritance tax paid
	const calcInheritanceTaxPaid = (calcNetEstate - calcTaxFreeThreshold) * calcTaxRate;

	// Taxable estate
	const calcTaxableEstate = calcNetEstate - calcTaxFreeThreshold;

	const steps = [
        {
			name: 'Property',
			heading: 'Property',
			description: 'Any amount of money that you have invested in one or more properties',
			component: <Property definitions={definitions} />,
		},
        {
			name: 'Assets',
			heading: 'Assets',
			description: 'Any other assets that have any substantial monetary value that you own or are owed to you',
			component: <Assets definitions={definitions} />,
		},
        {
			name: 'Liabilities',
			heading: 'Liabilities',
			description: 'Any outstanding payments or debts that you owe',
			component: <Liabilities definitions={definitions} />,
		},
		{
			name: 'Other information',
			heading: 'Other information',
			description: 'Some other information we need for your calculation...',
			component: <OtherInformation definitions={definitions} />,
		},
		{
			name: 'Results',
			heading: 'Results',
			description: 'We have calculated your potential inheritance tax payment - see your full personal breakdown below',
			component: (
				<Results
					inheritanceTaxPaid={formatNumber(calcInheritanceTaxPaid)}
					totalEstate={formatNumber(calcTotalEstate)}
					liabilityValue={formatNumber(totalLiabilityValue)}
					charityDonation={formatNumber(calcCharityDonation)}
					netEstate={formatNumber(calcNetEstate)}
					taxFreeThreshold={formatNumber(calcTaxFreeThreshold)}
					taxableEstate={formatNumber(calcTaxableEstate)}
					taxRate={calcTaxRate * 100}
					definitions={definitions}
				/>
			),
		},
	];

    return (
		<StepTracker
			steps={steps}
			storeName='inheritanceTax'
			data={query}
		/>
    );
};

InheritanceTax.propTypes = {
	definitions: PropTypes.array.isRequired,
}

export default InheritanceTax;