import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component imports
import InputSwitch from '../../../components/input-switch';
import Select from '../../../components/select';
import InputField from '../../../components/input-field';
import Content, { HTMLContent } from "../../../components/content-renderer";

const OtherInformation = ({
    definitions,
}) => {
    const PageContent = HTMLContent || Content;

    // React Redux hooks
	const dispatch = useDispatch();
    const query = useSelector(state => state.inheritanceTax);
    
    return (
        <>
            <div className='step-tracker__row'>
                <InputSwitch
                    id="direct-descendant-switch"
                    onChange={() => {
                        dispatch({
                            type: 'SET_DIRECT_DESCENDANT',
                            payload: !query.directDescendant
                        });
                    }}
                    checked={query.directDescendant}
                    modalHeading={definitions[3].frontmatter.title}
                    modalContent={<PageContent content={definitions[3].html} />}
                >
                    Leaving property to a direct descendant?
                </InputSwitch>
            </div>

            <div className='step-tracker__row'>
                <Select
                    labelText="Tax year"
                    id="tax-year-select"
                    onChange={(event) => dispatch({
                        type: 'SET_YEAR',
                        payload: event.target.value,
                    })}
                >
                    <Select.Option value='2024'>2024/25</Select.Option>
                    <Select.Option value='2023'>2023/24</Select.Option>
                    <Select.Option value='2022'>2022/23</Select.Option>
                    <Select.Option value='2021'>2021/22</Select.Option>
                    <Select.Option value='2020'>2020/21</Select.Option>
                    <Select.Option value='2019'>2019/20</Select.Option>
                    <Select.Option value='2018'>2018/19</Select.Option>
                    <Select.Option value='2017'>2017/18</Select.Option>
                </Select>
            </div>

            <div className='step-tracker__row'>
                <InputField
                    id='charity-donation'
                    labelText='Charity donation'
                    modalHeading={definitions[2].frontmatter.title}
                    modalContent={<PageContent content={definitions[2].html} />}
                    placeholder="0"
                    symbol={query.charityDonationAsPercentage ? 'percentage' : 'currency'}
                    min={0}
                    max={query.charityDonationAsPercentage ? 100 : undefined}
                    onChange={(event) => {
                        dispatch({
                            type: 'SET_CHARITY_DONATION',
                            payload: event.target.value === '' ? '' : event.target.value
                        });
                    }}
                    value={query.charityDonation}
                    checkboxText='Donation as % of estate'
                    checkboxOnChange={() => {
                        dispatch({
                            type: 'SET_CHARITY_DONATION_AS_PERCENTAGE',
                            payload: !query.charityDonationAsPercentage
                        });
                    }}
                    checkboxValue={query.charityDonationAsPercentage}
                />
            </div>
        </>
    );
};

OtherInformation.propTypes = {
    definitions: PropTypes.array.isRequired,
};

export default OtherInformation;