import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import InputTableRow from '../input-table/row';
import InputField from '../input-field';

const AssetRow = ({
   index,
   assetNameValue,
   assetValueValue,
   onChange,
   handleRemoveRow,
}) => {
    return (
        <InputTableRow
            heading={`${index+1}.`}
            handleRemove={handleRemoveRow}
        >
            <InputField
                id={`asset-name-${index}`}
                required
                labelText='Asset name'
                type='text'
                dataIndex={index}
                dataName='assetName'
                value={assetNameValue}
                onChange={onChange}
            />

            <InputField
                id={`asset-value-${index}`}
                required
                labelText='Asset value'
                symbol='currency'
                placeholder="0"
                min={0}
                dataIndex={index}
                dataName='assetValue'
                value={assetValueValue}
                onChange={onChange}
            />
        </InputTableRow>
    );
};

AssetRow.propTypes = {
   index: PropTypes.number,
   assetNameValue: PropTypes.any,
   assetValueValue: PropTypes.any,
   onChange: PropTypes.func,
   handleRemoveRow: PropTypes.func,
};

export default AssetRow;