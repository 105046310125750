import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components
import LiabilityRow from '../../../components/liability-row';
import InputTable from '../../../components/input-table';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Liabilities = ({
	definitions,
}) => {
	const PageContent = HTMLContent || Content;

    // React Redux hooks
	const dispatch = useDispatch();
	const query = useSelector(state => state.inheritanceTax);

    // Add a new blank liability object to liabilityState array
	const addLiability = () => {
		const blankLiability =  { liabilityName: '', liabilityValue: ''};

		dispatch({
			type: 'SET_LIABILITY_STATE',
			payload: [...query.liabilityState, {...blankLiability}]
		});
	};

	// Remove liability row
	const handleRemoveLiabilityRow = (idx) => {
		// Make a copy of existing array of children
		const updatedLiabilities = [...query.liabilityState];

		// Remove childcare row at clicked index
		updatedLiabilities.splice(idx, 1);
		
		// Set childcareState with newly spliced array values
		dispatch({
			type: 'SET_LIABILITY_STATE',
			payload: updatedLiabilities
		});
	};

	// When new liability row input is updated
	const handleLiabilityChange = (event) => {
		// Make a copy of existing array of liabilities
		const updatedLiabilities = [...query.liabilityState];

		// Use data-idx to locate the index of set of liability inputs
		// Use data-name to find out if it's the liability name or value that's updated and set this liability to the entered value
		updatedLiabilities[event.target.dataset.idx][event.target.dataset.name] = event.target.value;

		dispatch({
			type: 'SET_LIABILITY_STATE',
			payload: updatedLiabilities
		});
	};

    return (
        <InputTable
            heading='Liabilities'
            buttonText='Add a liability'
            onClick={(event) => {
                event.preventDefault();
                addLiability();
            }}
            modalHeading={definitions[5].frontmatter.title}
			modalContent={<PageContent content={definitions[5].body} />}
        >
            {query.liabilityState.map((item, index) => (
                <LiabilityRow
                    key={`liability-${index}`}
                    index={index}
                    liabilityNameValue={query.liabilityState[index].liabilityName}
                    liabilityValueValue={isNaN(query.liabilityState[index].liabilityValue) ? '' : query.liabilityState[index].liabilityValue}
                    onChange={handleLiabilityChange}
                    handleRemoveRow={(event) => {
                        event.preventDefault();
                        handleRemoveLiabilityRow(index);
                    }}
                />
            ))}
        </InputTable>
    );
};

Liabilities.propTypes = {
	definitions: PropTypes.array.isRequired,
};

export default Liabilities;