import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components
import InputField from '../../../components/input-field';
import AssetRow from '../../../components/asset-row';
import InputTable from '../../../components/input-table';
import InputTableRow from '../../../components/input-table/row';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Assets = ({
    definitions,
}) => {
    const PageContent = HTMLContent || Content;

    // React Redux hooks
	const dispatch = useDispatch();
	const query = useSelector(state => state.inheritanceTax);

    // Add a new blank asset object to assetState array
    const addAsset = () => {
        const blankAsset =  { assetName: '', assetValue: ''};

        dispatch({
            type: 'SET_ASSET_STATE',
            payload: [...query.assetState, {...blankAsset}]
        });
    };

    // Remove asset row
    const handleRemoveAssetRow = (idx) => {
        // Make a copy of existing array of assets
        const updatedAssets = [...query.assetState];

        // Remove asset row at clicked index
        updatedAssets.splice(idx, 1);
        
        // Set assetState with newly spliced array values
        dispatch({
            type: 'SET_ASSET_STATE',
            payload: updatedAssets
        });
    };

    // When new asset row input is updated
    const handleAssetChange = (event) => {
        // Make a copy of existing array of assets
        const updatedAssets = [...query.assetState];

        // Use data-idx to locate the index of set of asset inputs
        // Use data-name to find out if it's the asset name or value that's updated and set this asset to the entered value
        updatedAssets[event.target.dataset.idx][event.target.dataset.name] = event.target.value;

        dispatch({
            type: 'SET_ASSET_STATE',
            payload: updatedAssets
        });
    };

    return (
        <>
            <InputTable heading='Your assets'>
                <InputTableRow>
                    <InputField
                        id='cash'
                        labelText='Cash'
                        modalHeading={definitions[1].frontmatter.title}
                        modalContent={<PageContent content={definitions[1].html} />}
                        placeholder="0"
                        symbol='currency'
                        min={0}
                        onChange={(event) => {
                            dispatch({
                                type: 'SET_CASH',
                                payload: event.target.value === '' ? '' : event.target.value
                            });
                        }}
                        value={query.cash}
                    />

                    <InputField
                        id='investments'
                        labelText='Investments'
                        modalHeading={definitions[4].frontmatter.title}
                        modalContent={<PageContent content={definitions[4].html} />}
                        placeholder="0"
                        symbol='currency'
                        min={0}
                        onChange={(event) => {
                            dispatch({
                                type: 'SET_INVESTMENTS',
                                payload: event.target.value === '' ? '' : event.target.value
                            });
                        }}
                        value={query.investments}
                    />

                    <InputField
                        id='life-insurance'
                        labelText='Life insurance'
                        modalHeading={definitions[6].frontmatter.title}
                        modalContent={<PageContent content={definitions[6].html} />}
                        placeholder="0"
                        symbol='currency'
                        min={0}
                        onChange={(event) => {
                            dispatch({
                                type: 'SET_LIFE_INSURANCE',
                                payload: event.target.value === '' ? '' : event.target.value
                            });
                        }}
                        value={query.lifeInsurance}
                    />
                </InputTableRow>
            </InputTable>

            <InputTable
                heading='Other assets'
                buttonText='Add an asset'
                onClick={(event) => {
                    event.preventDefault();
                    addAsset();
                }}
                modalHeading={definitions[0].frontmatter.title}
                modalContent={<PageContent content={definitions[0].html} />}
            >
                {query.assetState.map((item, index) => (
                    <AssetRow
                        key={`asset-${index}`}
                        index={index}
                        assetNameValue={query.assetState[index].assetName}
                        assetValueValue={isNaN(query.assetState[index].assetValue) ? '' : query.assetState[index].assetValue}
                        onChange={handleAssetChange}
                        handleRemoveRow={(event) => {
                            event.preventDefault();
                            handleRemoveAssetRow(index);
                        }}
                    />
                ))}
            </InputTable>
        </>
    );
};

Assets.propTypes = {
    definitions: PropTypes.array.isRequired,
};

export default Assets;