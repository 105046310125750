import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Components
import InputField from '../../../components/input-field';
import InputTable from '../../../components/input-table';
import InputTableRow from '../../../components/input-table/row';
import Content, { HTMLContent } from "../../../components/content-renderer";

const Property = ({
    definitions,
}) => {
    const PageContent = HTMLContent || Content;

    // React Redux hooks
    const dispatch = useDispatch();
    const query = useSelector(state => state.inheritanceTax);

    // Add a new blank property object to propertyState array
	const addProperty = () => {
		const blankProperty = {
            propertyValue: '',
            propertyMortgage: ''
        };

		dispatch({
			type: 'SET_PROPERTY_STATE',
			payload: [...query.propertyState, {...blankProperty}]
		});
	};

	// Remove property row
	const handleRemovePropertyRow = (idx) => {
		// Make a copy of existing array of properties
		const updatedProperties = [...query.propertyState];

		// Remove property row at clicked index
		updatedProperties.splice(idx, 1);
		
		// Set propertyState with newly spliced array values
		dispatch({
			type: 'SET_PROPERTY_STATE',
			payload: updatedProperties
		});
	};

	// When new property row input is updated
	const handlePropertyChange = (event) => {
		// Make a copy of existing array of properties
		const updatedProperties = [...query.propertyState];

		// Use data-idx to locate the index of set of property inputs
		// Use data-name to find out if it's the property valuation or mortgage that's updated and set this property to the entered value
		updatedProperties[event.target.dataset.idx][event.target.dataset.name] = event.target.value;

		dispatch({
			type: 'SET_PROPERTY_STATE',
			payload: updatedProperties
		});
	};

    return (
        <>
            <InputTable
                heading='Main property'
                modalHeading={definitions[7].frontmatter.title}
                modalContent={<PageContent content={definitions[7].html} />}
            >
                <InputTableRow>
                    <InputField
                        id='main-property-value'
                        labelText='Value'
                        modalHeading={definitions[10].frontmatter.title}
                        modalContent={<PageContent content={definitions[10].html} />}
                        placeholder="0"
                        symbol='currency'
                        min={0}
                        onChange={(event) => {
                            dispatch({
                                type: 'SET_MAIN_PROPERTY_VALUE',
                                payload: event.target.value === '' ? '' : event.target.value
                            });
                        }}
                        value={query.mainPropertyValue}
                    />

                    <InputField
                        id='main-property-mortgage'
                        labelText='Outstanding mortgage'
                        modalHeading={definitions[9].frontmatter.title}
                        modalContent={<PageContent content={definitions[9].html} />}
                        placeholder="0"
                        symbol='currency'
                        min={0}
                        onChange={(event) => {
                            dispatch({
                                type: 'SET_MAIN_PROPERTY_MORTGAGE',
                                payload: event.target.value === '' ? '' : event.target.value
                            });
                        }}
                        value={query.mainPropertyMortgage}
                    />
                </InputTableRow>
            </InputTable>
            
            <InputTable
                heading='Other properties'
                buttonText='Add a property'
                onClick={(event) => {
                    event.preventDefault();
                    addProperty();
                }}
            >
                {query.propertyState.map((item, index) => (
                    <InputTableRow
                        heading={`${index+1}.`}
                        handleRemove={(event) => {
                            event.preventDefault();
                            handleRemovePropertyRow(index);
                        }}
                        key={index}
                    >
                        <InputField
                            id={`property-value-${index}`}
                            labelText='Value'
                            placeholder="0"
                            symbol='currency'
                            min={0}
                            onChange={handlePropertyChange}
                            value={isNaN(query.propertyState[index].propertyValue) ? '' : query.propertyState[index].propertyValue}
                            dataIndex={index}
                            dataName='propertyValue'
                        />

                        <InputField
                            id={`property-mortgage-${index}`}
                            labelText='Outstanding mortgage'
                            symbol='currency'
                            placeholder="0"
                            min={0}
                            dataIndex={index}
                            dataName='propertyMortgage'
                            value={isNaN(query.propertyState[index].propertyMortgage) ? '' : query.propertyState[index].propertyMortgage}
                            onChange={handlePropertyChange}
                        />
                    </InputTableRow>
                ))}
            </InputTable>
        </>
    );
};

Property.propTypes = {
    definitions: PropTypes.array.isRequired,
};

export default Property;